/* ------------------------------ General / Global Styles  ------------------------------*/

/* ---------- Main Styles ----------*/
.App {
  display: flex;
  justify-content: flex-start;
  background-color: var(--color-background-sidebar);
}
.page {
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: var(--color-background-app);
  position: relative;
  height: 100vh;
}
.max-height {
  max-height: 400px;
  overflow-y: auto;
}
.left-center {
  left: 17px;
}
.card {
  border-radius: 0.75rem;
  box-shadow: none;
}
.card .currencyCode {
  color: #fff;
  opacity: 0.75;
}
/* ---------- Buttons Styles----------*/

.btn-general {
  border: none;
  border-radius: 0.25rem;
  padding: 0 1rem;
  min-width: 80px;
  transition: all 0.3s ease-in-out;
  z-index: 100;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(64, 68, 82, 0.16) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(64, 68, 82, 0.08) 0px 2px 5px 0px;
}
.btn-custom-primary {
  background-color: var(--color-primary);
  color: #fff;
}
.btn-custom-primary:disabled {
  background-color: #fff;
  color: #d8d8d8;
}
.btn-custom-white {
  background-color: var(--color-background-btn);

  color: var(--color-text);
}
.btn-custom-white:hover {
  color: var(--color-text);
}
.btn-custom-white:disabled {
  color: var(--color-text-disabled);
}
.btn-custom-white:hover:not([disabled]) {
  background-color: var(--hover);
}
.btn-custom-red {
  border: 1px solid;
  border-color: var(--border--color-red);
  background-color: var(--bg-red);
  color: var(--color-red);
}
.btn-custom-red:hover {
  border-color: #9a002e;
  background-color: #9a002e;
  color: var(--color-text-white);
}
.btn-outline {
  border: none !important;
  border-radius: 0.25rem !important;
  padding: 0 1rem !important;
  background-color: transparent !important;
  color: #333 !important;
}
.btn-outline:hover:not([disabled]) {
  transition: all 0.5s ease;
  background-color: #dad4d4 !important;
}
.clean-filter-btn {
  border-radius: 50px;
  padding: 0.2rem 1rem;
  height: 1.8rem !important;
}
@media (max-width: 768px) {
  .btn-custom-red:hover {
    border: 1px solid;
    border-radius: 5px;
    border-color: var(--border--color-red);
    min-height: 28px;
    background-color: var(--bg-red);
    color: var(--color-red);
    transition: all 0.3s ease-in-out;
  }
}

/* ---------- Form Styles----------*/
.error-content {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
}
.form-group {
  margin-bottom: 1rem;
}

/* ---------- Text Styles----------*/
.text-strikethrough {
  text-decoration: line-through;
}
.text-strikethrough .integer {
  color: var(--color-text-light);
  text-decoration: line-through;
}

/* ---------- Cards Styles----------*/
.card-popup {
  background-color: var(--color-background-app);
  border-radius: 0.25rem;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

/* ---------- Badge Styles----------*/
.confirmBadge,
.cancelBadge {
  height: 30px;
  width: 100%;
  max-width: 200px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  filter: grayscale(100%);
  border: 1px solid var(--color-border-table);
  border-radius: 0.25rem;
}
.confirmBadge {
  background-color: var(--color-background-app);
}
