.dropdown-menu {
  left: auto !important;
  right: 10px !important;
}

#logoutDropdown .dropdown {
  position: relative;
}

#logoutDropdown .dropdown-menu {
  right: 0 !important;
  left: auto;
}

#companySelector.dropdown {
  position: relative !important;
}

#companySelector .dropdown-menu {
  left: 14px;
  width: 17rem;
  top: 50px;
}
#companySelector .dropdown-item {
  flex-direction: row;
  align-items: center;
}
