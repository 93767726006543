.loaderDiv {
  margin-top: 0;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: 10;
}

.loaderDiv > .text-spinner {
  color: var(--color-primary);
}
