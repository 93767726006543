.menuHeaderContainer {
  color: rgba(51, 51, 51, 0.5);
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 1rem !important;
  transition: background-color ease-in-out 0.15s, color ease-in-out 0.15s;
  justify-content: space-between;
  cursor: pointer;
  height: 50px !important;
}
.menuItemContainer {
  color: var(--color-text-sidebar);
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 1rem !important;
  transition: background-color ease-in-out 0.15s, color ease-in-out 0.15s;
  cursor: pointer;
}

.menuSelected {
  border-left: 4px solid #503ec4;
  color: var(--color-primary) !important;
  font-weight: 500;
}

.menuItemContainer:hover {
  background-color: var(--hover) !important;
}
@media (max-width: 768px) {
  .menuItemContainer:hover {
    background-color: transparent !important;
  }
}
