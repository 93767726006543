.filter-card {
  border: 1px solid var(--color-border-table);
  border-radius: 12px;
  transition: all 0.3s ease;
  cursor: pointer;
  width: 100%;
  padding: 0.875rem;
}
.filter-card:hover {
  border: 1px solid var(--color-primary) !important;
}
.filter-card-selected {
  border: 1px solid var(--color-primary) !important;
  transition: all 0.3s ease;
  box-shadow: 0px 0px 0px 1px var(--color-primary) !important;
}
@media (max-width: 768px) {
  .filter-card:hover {
    border: 1px solid var(--color-border-table);
  }
}
