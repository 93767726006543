.alert-success {
  background-color: var(--bg-green);
  border: 1px solid var(--border--color-green);
  color: var(--color-green);
}
.alert-error {
  background-color: var(--bg-red);
  border: 1px solid var(--border--color-red);
  color: var(--color-red);
}
.alert-warning {
  background-color: var(--bg-orange);
  border: 1px solid var(--border--color-orange);
  color: var(--color-orange);
}
.alert-info {
  background-color: var(--bg-blue);
  border: 1px solid var(--border--color-blue);
  color: var(--color-blue);
}
