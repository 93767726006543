.buttonGallery {
  outline: none;
  border: 2px var(--color-border-table) dashed;
  border-radius: 0.8rem;
  cursor: pointer;
  font-size: 1.5rem;
  color: var(--color-text-light);
  background-color: var(--color-background-card);
  transition: opacity 0.3s ease-in-out;
}
.buttonGallery:hover {
  opacity: 0.7;
}
.buttonGallery span {
  font-size: 14px;
}
.error {
  border: 2px var(--color-red) dashed;
}
@media (max-width: 768px) {
  .buttonGallery:hover {
    opacity: 1;
  }
}
