/* Clases para títulos */
.custom-title {
  font-size: 1.75rem; /* 32px / 16px = 2rem */
}

/* Clases para subtítulos */
.ctm-subtitle {
  font-size: 1.25rem !important; /* 18px / 16px = 1.125rem */
}

.ctm-text-medium {
  font-size: 1rem; /* 16px / 16px = 1rem */
}
/* Clases para texto regular */
.ctm-text {
  font-size: 0.875rem; /* 16px / 16px = 1rem */
}

.ctm-text-small {
  font-size: 0.75rem !important;
}
