.btn-outline-light:hover {
  background-color: var(--hover) !important;
}
.btn-outline-light {
  color: var(--color-text-sidebar) !important;
}

tr .btn-outline-light:hover {
  background-color: var(--hover-table) !important;
}
@media (max-width: 768px) {
  .btn-outline-light:hover {
    background-color: transparent !important;
  }
}
