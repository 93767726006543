.selector {
  color: rgba(51, 51, 51, 0.85);
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 1rem;
  transition: background-color ease-in-out 0.15s, color ease-in-out 0.15s;
  cursor: pointer;
}
.menuItemContainer:hover {
  background-color: var(--hover);
}

.companySelector:hover {
  background-color: #f5f6f8;
}
.btnCompanySelector {
  height: auto;
  padding: 3px;
}
