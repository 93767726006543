.card-zoom {
  background-color: var(--color-background-card);
  padding: 2rem;
  width: 100%;
  border-radius: 1rem;
  position: relative;
  max-height: 300px;
}

.card-zoom-icon {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-primary);
  width: fit-content;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
}
