.badge {
  border-radius: 4px;
  line-height: normal;
  padding: 1px 6px;
  border: 1px solid;
  height: 30px;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  font-size: 12px !important;
}
.badge i {
  font-size: 12px !important;
  margin-bottom: -4px !important;
}

.badge .icon-checkmark3 {
  font-size: 0.75rem;
}
/* BADGES */
.badge-red {
  color: var(--color-red);
  background-color: var(--bg-red);
}
.badge-green {
  color: var(--color-green);
  background-color: var(--bg-green);
}
.badge-blue {
  color: var(--color-blue);
  background-color: var(--bg-blue);
}
.badge-orange {
  color: var(--color-orange);
  background-color: var(--bg-orange);
}
.badge-gray {
  color: var(--color-gray);
  background-color: var(--bg-gray);
}
/* BADGES NIVEL DE AFILIADO */
.badge-diamond {
  border-color: #242424;
  background-color: #545454;
  color: #fff;
}

.badge-platinum-pro {
  border-color: #676767;
  background-color: #8e8e8e;
  color: #fff;
}
.badge-platinum {
  border-color: #6b6b6b;
  background-color: #dbdbdb;
  color: #000;
}
.badge-gold {
  border-color: #996e25;
  background-color: #ffd261;
  color: #000;
}
.badge-silver {
  border-color: #6997b7;
  background-color: #aee4ff;
  color: #000;
}

.badge-default {
  border: 0;
  border-radius: 5px;

  background-color: var(--color-background-btn);
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(64, 68, 82, 0.16) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(64, 68, 82, 0.08) 0px 2px 5px 0px;
  z-index: 100;
  color: var(--color-text);
}
