.navbar {
  box-shadow: none !important;
}
.header-container {
  background-color: var(--color-background-app);
  color: #fff;
  padding: 0.75rem 1rem;
  z-index: 80;
}

@media (min-width: 769px) {
  .header-container .navbar-brand {
    min-width: 17.5rem;
  }
}

@media (min-width: 992px) {
  .header-container .collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
}
.content-btn-nav {
  border: none;
  border-radius: 100px;
  padding: 8px;
  line-height: 20px;
  transition: all 0.3s ease;
}
.content-btn-nav:hover {
  background-color: #f5f6f8;
}

.avatar {
  background-color: lightgreen;
  border-radius: 50%;
  width: 35px;
  height: 35px;
}
.dropdown-item > i {
  margin-right: 0 !important;
}
