.input-style,
.PhoneInput .PhoneInputInput {
  border: 1px solid var(--color-border-table);
  border-radius: 0.375rem;
  min-height: 34px;
  background-color: var(--color-background-btn);
  width: 100%;
  outline: 0;
  color: var(--color-text);
  padding: 0 0.575rem;
}
.disabled .PhoneInputInput {
  background: var(--color-background-disabled);
}
.input-style:focus,
.PhoneInputInput:focus {
  border: 1px solid var(--color-primary);
}
.input-style.input-error {
  border: 1px solid var(--color-red);
}
.input-error .PhoneInputInput {
  border: 1px solid var(--color-red);
}
.input-icon {
  padding: 0.4375rem 0.875rem;
  background-color: #fafafa;
  color: var(--color-text);
  border: 1px solid var(--color-border-table) !important;
  margin-right: -1px;
  border-top-left-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.input-icon + .input-style,
.file-btn button {
  border-top-left-radius: 0rem;
  border-bottom-left-radius: 0rem;
}

.file-btn > .remove-file {
  color: var(--color-subtitle);
  background-color: transparent;
  border-top-right-radius: 0rem;
  border-bottom-right-radius: 0rem;
  border: 1px solid var(--color-border-table);
  border-left: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--color-text);
  -webkit-box-shadow: 0 0 0px 40rem var(--color-background-btn) inset;
}
.input-end {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.preview-image {
  max-height: 300px;
  height: 100%;
  width: 100%;
  border-style: none;
  object-fit: contain;
}
.file-dropzone {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  background-color: var(--color-background-card);
  border: 1px solid var(--color-border-table);
  border-radius: 0.375rem;
  font-size: 1.25rem;
  text-align: center;
  vertical-align: middle;
  color: var(--color-text);
}

.textarea-style {
  padding: 0.25rem 0.575rem;
}
.send-file {
  border: 1px solid var(--color-border-table);
  border-left: none;
  color: var(--color-subtitle);
  background-color: transparent;
}
.send-file:hover {
  background-color: transparent;
}
/* 
[data-theme='dark'] {
  .input-file-container button {
    border: 1px solid var(--color-border-table);
    border-left: none;
  }
} */
.react-time-picker__wrapper {
  border: none !important;
}
.react-time-picker__inputGroup__input:invalid {
  background: none !important;
}

.disabled.PhoneInput {
  background: none;
}
.input-disabled {
  background-color: var(--color-border-table) !important;
}

.input-icon.end {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important;
}
.rdp-dropdown_year,
.rdp-dropdown_month {
  color: var(--color-subtitle) !important;
}

.rdp-dropdown:focus-visible:not([disabled]) + .rdp-caption_label {
  color: var(--color-subtitle) !important;
  background-color: var(--color-background-app) !important;
  border: 1px solid var(--color-border-table) !important;
  border-radius: 6px;
}
.rdp-dropdown {
  background-color: var(--color-background-sidebar) !important;
  color: var(--color-text) !important;
  padding: 10px !important;
}
.rdp-nav_button_previous,
.rdp-nav_button_next {
  color: var(--color-subtitle) !important;
}
.rdp-nav_button_previous:hover,
.rdp-nav_button_next:hover {
  background-color: var(--hover) !important;
}
.rdp-day:hover,
.rdp-day_selected {
  background-color: var(--color-primary) !important;
  color: #fff !important;
}
.rdp-table {
  color: var(--color-text) !important;
}
@media (max-width: 768px) {
  .rdp-day:hover,
  .rdp-day_selected {
    background-color: transparent;
  }
  .rdp-nav_button_previous:hover,
  .rdp-nav_button_next:hover {
    background-color: transparent;
  }
}
