.modal {
  color: var(--color-text);
}
.modal-content {
  border-radius: 1.5rem !important;
  background-color: var(--color-background-sidebar) !important;
  box-shadow: 0 7px 14px 0 rgba(65, 69, 82, 0.08), 0 3px 6px 0 rgba(0, 0, 0, 0.12) !important;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1050;
  display: none;
}

.modal-header {
  padding: 15px !important;
}
.modal-header h5 {
  font-size: 1rem;
  font-weight: bold;
}

.modal-header,
.modal-background {
  border-bottom: 1px solid var(--color-border-table) !important;
  background-color: var(--color-background-sidebar);
  color: var(--color-general);
}
.modal-body {
  padding: 20px !important;
}
.modal-background {
  background-color: var(--color-background-sidebar);
  padding: 0.75rem 1.25rem;
  color: var(--color-text);
}
.modal-title {
  color: var(--color-subtitle);
}

.modal-footer {
  padding: 1rem 1.25rem !important;
  background-color: var(--color-background-sidebar);
  color: var(--color-text);
}

.custom-popup-overlay {
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
}
.custom-popup-content {
  width: 100%;
  margin: 0 auto !important;
}
