.performance-container {
  width: 250px;
}

.performance-header {
  padding: 2px;
  width: 30px;
  border: 1px solid grey;
}
.performance-check {
  padding: 2px;
  width: 30px;
  border: 1px solid grey;
  border-top: none;
}

.small-table {
  width: 210px;
  font-size: 12px;
}

.small-cell {
  width: 20px;
  height: 20px;
  text-align: center;
  padding: 0 !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
