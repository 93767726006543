.searchbar {
  width: 100%;
  max-width: 30rem;
}

.searchbar .form-control {
  border: 0;
  border-radius: 0.5rem;
  color: var(--color-text);
  background-color: var(--color-background-app);
  transition: none;
}

@media (max-width: 992px) {
  .searchbar {
    max-width: none;
  }

  .navbar-collapse {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    margin-left: -1rem;
    margin-right: -1rem;
  }
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5715em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5715;
}

.searchbar .form-control:hover,
.searchbar .form-control:focus {
  color: var(--color-text-sidebar);
  background-color: var(--hover);
  box-shadow: none !important;
}

.form-control::placeholder {
  color: var(--color-text-sidebar);
  font-weight: 600;
}

.form-group-feedback {
  position: relative;
}

.searchbar > .form-control-feedback {
  position: absolute;
  top: 11px !important;
  color: #333;
  padding-left: 1rem;
  padding-right: 1rem;
  line-height: calc(1.5715em + 1rem);
  min-width: 1rem;
}
