.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: var(--color-background-app);
  background-clip: border-box;
  border: 1px solid var(--color-border-table);
  border-radius: 0.25rem;
}

.cardBody {
  padding: 1rem;
}

.card .content-divider > span,
.modal .content-divider > span,
.tab-content-bordered .content-divider > span {
  background-color: #fff;
}
