.btnSelected {
  border: none;
  border-radius: 0.25rem;
  padding: 0 1rem;
  background-color: var(--color-primary);
  color: #fff;
  min-width: 80px;
  transition: background-color 0.3s ease-in-out;
}

/* .buttonWellet:focus {
  outline: none;
}
.buttonWellet:hover {
  background-color: #334297;
  outline: none;
} */

.buttonWellet .text {
  font-weight: 700;
}

.btnClickeable {
  background-color: var(--color-medium-gray);
}
.disabledOpacity {
  opacity: 0.5;
}
@media (max-width: 768px) {
  .buttonWellet:hover {
    background-color: var(--color-primary);
  }
}
