td,
th,
tbdoy,
table,
thead tr {
  border-left: none !important;
  border-right: none !important;
  /* border-right: 0 !important; */
}
td:not(.rdp-cell) {
  height: 30px;
  padding: 8px !important;
  margin: 0 !important;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
}

tr {
  border-top: 1px solid var(--color-border-table);
  height: 100%;
}
tr:last-child {
  border-bottom: none;
}

td {
  border: none !important;
}
th {
  border: none !important;
  padding: 0.5rem !important;
  text-align: left;
  white-space: nowrap; /* Evita saltos de línea */
  overflow: hidden; /* Oculta el texto que no cabe */
  text-overflow: ellipsis;
}

.currency-column,
.check-column {
  width: 20px !important;
}
td .currencyCode {
  font-weight: 500;
  margin-left: 10px;
}
input[type='checkbox'],
.check {
  border-width: 0px !important;
  /* box-shadow: 0 4px 6px rgba(216, 222, 228, 0.5) !important; */
  border-radius: 8px !important;
}
.table-row {
  cursor: pointer;
  transition: background-color 0.3s ease;
  z-index: 0;
}
.table-row:hover {
  cursor: pointer;
  background-color: var(--hover);
  z-index: 0;
}

.visible {
  opacity: 1;
  /* width: auto; */
  /* transition: opacity 0.3s ease, margin 0.3s ease; */
}

.hidden {
  opacity: 0;
  /* width: 0; */
  /* transition: opacity 0.3s ease, margin 0.3s ease; */
}
@media (max-width: 768px) {
  .table-row:hover {
    background-color: transparent;
  }
}

/* .td-btn-group {
  background-color: var(--color-background-app);
}

.btn-left {
  border-top: 1px solid var(--color-border-table);
  border-bottom: 1px solid var(--color-border-table);
  border-left: 1px solid var(--color-border-table);
  border-right: 1px solid var(--color-border-table);
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  z-index: 100;
}

.btn-center {
  border-top: 1px solid var(--color-border-table);
  border-bottom: 1px solid var(--color-border-table);
  border-left: 1px solid var(--color-background-app);
  border-right: 1px solid var(--color-background-app);
  z-index: 100;
}

.btn-right {
  border-top: 1px solid var(--color-background-app);
  border-bottom: 1px solid var(--color-background-app);
  border-left: 1px solid var(--color-background-app);
  border-right: 1px solid var(--color-background-app);
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  z-index: 100;
}

.table-row:hover .btn-right {
  border: 1px solid var(--color-border-table);
}
.btn-left,
.btn-center,
.btn-right {
  background-color: var(--color-background-app);
}
.btn-left:hover,
.btn-right:hover {
  border-color: var(--color-border-table-light) !important;
}
.btn-center:hover {
  border-color: var(--color-border-table) !important;
  border-left: 1px solidvar(--color-primary);
  border-right: 1px solidvar(--color-primary);
} */
