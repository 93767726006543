/* Agrega estilos generales */
.card-gallery {
  border-radius: 0.8rem;
  background-color: var(--color-background-card);
  transition: all 0.2s ease-in-out;
  position: relative;
  overflow: hidden;
  height: 200px;
}
.card-gallery-size {
  width: 200px;
  max-width: 200px;
  /* min-width: 150px; */
}
.card-gallery:hover {
  /* transform: scale(1.01); */
  /* opacity: 0.2; */
  background-color: rgba(0, 0, 0, 0.5);
}
.card-gallery:hover .image-container img {
  /* transform: scale(1.01); */
  opacity: 0.3;
}

.image-container {
  margin: 0 auto;
  cursor: pointer;
}

.image-container img {
  border-radius: 0.8rem;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.2s ease-in-out;
}
.image-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center; /* Centro horizontal */
  align-items: center; /* Centro vertical */
  padding: 5px;
  opacity: 0; /* Inicialmente oculto */
  transition: opacity 0.2s ease-in-out;
  z-index: 100;
}

.card-gallery:hover .image-overlay {
  opacity: 1; /* Visible en hover */
}
.hoverIconConatiner {
  margin-left: 5px;
}
.hoverIconConatiner:hover .hoverIcon {
  color: var(--color-text) !important;
}
@media (max-width: 640px) {
  .card-gallery-size {
    width: 100%;
  }
}
