.profile-section {
  height: 70vh;
}
.main-section {
  min-height: 600px;
}
.data-section {
  max-height: 300px;
  height: auto;
}
.height-input-content {
  height: 50px;
}
.border-right {
  border-right: 1px solid var(--color-border-table);
}
.border-bottom {
  border-bottom: 1px solid var(--color-border-table);
}

.profile-container {
  padding: 1.25rem 0;
}


@media (max-width: 1366px) {
  .main-section {
    min-height: 400px;
  }
}
@media (max-width: 575px) {
  .main-section {
    min-height: 250px;
    display: flex;
    justify-content: center;
  }

  .profile-container {
    padding-bottom: 5rem;
  }
}
