/* Toast Container */
.toast-container {
  position: fixed;
  left: 0;
  right: 0;
  text-align: center;
  bottom: 20px;
  z-index: 1000;
}

/* Toast Styles */
.toasts {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  max-width: 400px;
  margin: 0 auto;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

/* Mostrar Toast */
.toast-show {
  opacity: 1;
}

/* Estilos para el Toast de éxito */
.toast-success {
  background-color: #28a745; /* Color de fondo para éxito */
}

/* Estilos para el Toast de error */
.toast-error {
  background-color: #dc3545; /* Color de fondo para error */
}
.toasts-body {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/* Ícono del Toast */
.toasts-icon {
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
}

/* Añade estilos adicionales según sea necesario */
