.info-description {
  /* color: #687385; */
  font-size: 0.875rem;
}
.info-value {
  /* color: #414552; */
  font-size: 0.875rem;
}
.info-value .moneyCents {
  margin-top: 2px;
  font-size: 0.575rem;
}
.payment-title {
  font-size: 1.6rem;
  font-weight: bold;
  gap: 5px;
}
.payment-title .badge {
  margin-top: 4px;
}
.payment-title .moneyCents {
  font-size: 1rem;
  margin-top: 5px;
}
.payment-title .currencyCode {
  /* color: #545969; */
  font-weight: normal;
}
@media only screen and (min-width: 768px) {
  .right-divider {
    border-right: 1px solid var(--color-border-table);
  }
}

.payment-pagination {
  border-top: 1px solid var(--color-border-table);
}

.payment-pagination > div {
  gap: 25px;
}

.actionList {
  padding: 0;
  margin: 0;
}

.actionList li {
  list-style: none;
}
