:root {
  --color-primary: #503ec4;

  /* Backgrounds */
  --color-background-app: #fff;
  --color-background-white: #fff;
  --color-background-header: #fff;
  --color-background-btn: #fff;
  --color-background-sidebar: #fafbfd;
  --color-background-table: #fff;
  --color-background-card: #eee;
  --color-background-gallery-card: #fff;
  --color-background-disabled: #d8d8d8;

  /* Borders */
  --color-border-table: #ddd;
  --color-border-table-dark: #87909f;
  --color-border-primary: #503ec4;

  /* Titles & Texts */
  --color-text-white: #fff:
  --color-text: #414552;
  --color-text-light: #687385;
  --color-title: #30313d;
  --color-subtitle: #1a1b25;
  --color-text-sidebar: #545969;
  --color-text-white: #fff;
  --color-text-disabled: #d8d8d8;
  --color-text-table: #353a44;

  /* Hovers & Focus */
  --hover: #eee;
  --hover-table: #dad7d7;

  /*Badges &  Others */
  --color-red: #b3093c;
  --color-green: #006908;
  --color-blue: #1b6ea8;
  --color-orange: #c84801;
  --color-gray: #808080;

  --border--color-red: #f88d94;
  --border--color-green: #a6eb84;
  --border--color-blue: #7fbae4;
  --border--color-orange: #ffb46f;
  --border--color-gray: #c9c9c9;

  --bg-red: #ffe7f2;
  --bg-green: #e3ffd2;
  --bg-blue: #e9f6ff;
  --bg-orange: #fef9da;
  --bg-gray: #f5f5f5;
  --bg-primary: #503ec4;
  /*Scroll Bar */
  --color-background-scrollbars: #eee;
  --color-scrollbars-thumb: #999;

  /*Icons Text Editor */
  --image-btn-editor: url('../images/lightMode/addImg.png');
  --color-btn-editor: url('../images/lightMode/colorpicker.png');
  --bold-btn-editor: url('../images/lightMode/bold.png');
  --italic-btn-editor: url('../images/lightMode/italic.png');
  --underline-btn-editor: url('../images/lightMode/underline.png');
  --striketrhough-btn-editor: url('../images/lightMode/striketrhough.png');
  --monospace-btn-editor: url('../images/lightMode/monospace.png');
  --superscript-btn-editor: url('../images/lightMode/superscript.png');
  --subscript-btn-editor: url('../images/lightMode/subscript.png');
  --unordered-btn-editor: url('../images/lightMode/unordered.png');
  --ordered-btn-editor: url('../images/lightMode/ordered.png');
  --indent-btn-editor: url('../images/lightMode/indent.png');
  --outdent-btn-editor: url('../images/lightMode/outdent.png');
  --left-btn-editor: url('../images/lightMode/left.png');
  --center-btn-editor: url('../images/lightMode/center.png');
  --right-btn-editor: url('../images/lightMode/right.png');
  --justify-btn-editor: url('../images/lightMode/justify.png');
  --link-btn-editor: url('../images/lightMode/link.png');
  --unlink-btn-editor: url('../images/lightMode/unlink.png');
  --embedded-btn-editor: url('../images/lightMode/embedded.png');
  --emoji-btn-editor: url('../images/lightMode/emoji.png');
  --remove-btn-editor: url('../images/lightMode/remove.png');
  --undo-btn-editor: url('../images/lightMode/undo.png');
  --redo-btn-editor: url('../images/lightMode/redo.png');
}
[data-theme='dark'] {
  /* Backgrounds */
  --color-primary: #503ec4;
  --color-background-app: #181a1b;
  --color-background-white: #fff;
  --color-background-btn: #181a1b;
  --color-background-header: #fff;
  --color-background-sidebar: #1a1c1d;
  --color-background-table: #fff;
  --color-background-card: #353535;
  --color-background-gallery-card: #2c2d33;
  --color-background-disabled: #2e3234;

  /* Borders */
  --color-border-table: #3a3e41;
  --color-border-table-dark: #87909f;
  --color-border-primary: #503ec4;

  /* Titles & Texts */
  --color-text: #bab4ab;
  --color-text-light: #9d9588;
  --color-title: #30313d;
  --color-subtitle: #d4d0cb;
  --color-text-sidebar: rgba(200, 195, 188, 0.85);
  --color-text-white: #fff;
  --color-text-disabled: #6f6e6e;
  --color-text-table: #353a44;

  /* Hovers & Focus */
  --hover: #1d1f20;
  --hover-table: #333333;

  /*Badges &  Others */
  --color-red: #ff274e;
  --color-green: #21c22e;
  --color-blue: #35a0eb;
  --color-orange: #ff7122;
  --color-gray: #808080;

  --border--color-red: #ff274e;
  --border--color-green: #21c22e;
  --border--color-blue: #35a0eb;
  --border--color-orange: #ff7122;
  --border--color-gray: #808080;

  --bg-red: #0f0f0f;
  --bg-green: #0f0f0f;
  --bg-blue: #0f0f0f;
  --bg-orange: #0f0f0f;
  --bg-gray: #0f0f0f;
  --bg-primary: #503ec4;

  /*Scroll Bar */
  --color-background-scrollbars: #181a1b;
  --color-scrollbars-thumb: #52585c;

  /*Icons Text Editor */
  --image-btn-editor: url('../images/darkMode/addImg.png');
  --color-btn-editor: url('../images/darkMode/colorpicker.png');
  --bold-btn-editor: url('../images/darkMode/bold.png');
  --italic-btn-editor: url('../images/darkMode/italic.png');
  --underline-btn-editor: url('../images/darkMode/underline.png');
  --striketrhough-btn-editor: url('../images/darkMode/striketrhough.png');
  --monospace-btn-editor: url('../images/darkMode/monospace.png');
  --superscript-btn-editor: url('../images/darkMode/superscript.png');
  --subscript-btn-editor: url('../images/darkMode/subscript.png');
  --unordered-btn-editor: url('../images/darkMode/unordered.png');
  --ordered-btn-editor: url('../images/darkMode/ordered.png');
  --indent-btn-editor: url('../images/darkMode/indent.png');
  --outdent-btn-editor: url('../images/darkMode/outdent.png');
  --left-btn-editor: url('../images/darkMode/left.png');
  --center-btn-editor: url('../images/darkMode/center.png');
  --right-btn-editor: url('../images/darkMode/right.png');
  --justify-btn-editor: url('../images/darkMode/justify.png');
  --link-btn-editor: url('../images/darkMode/link.png');
  --unlink-btn-editor: url('../images/darkMode/unlink.png');
  --embedded-btn-editor: url('../images/darkMode/embedded.png');
  --emoji-btn-editor: url('../images/darkMode/emoji.png');
  --remove-btn-editor: url('../images/darkMode/remove.png');
  --undo-btn-editor: url('../images/darkMode/undo.png');
  --redo-btn-editor: url('../images/darkMode/redo.png');
}

/* --------------------------------------------- Borders Colors ------------------------------------------- */
.border-table {
  border: 1px solid var(--color-border-table);
}
.border-table-top {
  border-top: 1px solid var(--color-border-table);
}
.border-table-bottom {
  border-bottom: 1px solid var(--color-border-table);
}
.border-table-left {
  border-left: 1px solid var(--color-border-table);
}
.border-table-right {
  border-right: 1px solid var(--color-border-table);
}
.border-red {
  border-color: var(--border--color-red);
}
.border-green {
  border-color: var(--border--color-green);
}
.border-blue {
  border-color: var(--border--color-blue);
}
.border-orange {
  border-color: var(--border--color-orange);
}
.border-gray {
  border-color: var(--border--color-gray);
}
/* --------------------------------------------- General Color Texts ------------------------------------------- */
.text-primary {
  color: var(--color-text) !important;
}

.text-general {
  color: var(--color-text);
}
.text-sidebar-link {
  color: var(--color-text-sidebar);
}
.text-light {
  color: var(--color-text-light) !important;
}
.text-white {
  color: var(--color-text-white: ) !important;
}
.text-subtitle {
  color: var(--color-subtitle);
}

/* --------------------------------------------- General CSS ------------------------------------------- */
.fa,
.fab,
.fal,
.far,
.fas {
  top: 0 !important;
}

/* 
CODIGO PARA OCULTAR LAS FLECHAS DE LOS INPUTS NUMBER POR DEFAULT
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


input[type='number'] {
  -moz-appearance: textfield;
} */

@media (max-width: 768px) {
  .btn-custom-white:hover {
    background-color: var(--color-background-btn);
  }
}
